import React from 'react'
import styled from 'styled-components'
import Section from '../../../resuable/section/section'
import { useStaticQuery, graphql } from 'gatsby'
import Accordian from '../../../resuable/accordian/accordian'
import { colors } from '../../../../utils/styles/styles'

const StyledPricingFaqSection = styled.div`
  width: 100%;
  padding: 40px 20px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  div.faq {
    width: 100%;
    max-width: 650px;
    margin: 10px 0px;
    border: 1px solid #555;
    .container__title {
      padding: 8px 12px;
      background: ${props => props.theme.name === 'dark' ? '#1E1E1E' : '#eee'};
      border-bottom: 0px;
      h5 {
        color: ${props => props.theme.name === 'dark' ? '#fdfdfd' : '#555'};
        font-weight: 400;
        font-size: 18px;
      }
    }
    .container__body {
      box-sizing: border-box;
      padding: inherit;
      padding-left: 18px;
      padding-right: 18px;
      background: ${props => props.theme.name === 'dark' ? '#000' : '#ddd'};
      color: ${props => props.theme.name === 'dark' ? '#ccc' : '#444'};
    }
  }
`

const PricingFaqSection = (props) => {
  const data = useStaticQuery(graphql`
    query pricingFAQS {
      wordpressPage(slug: {eq: "pricing"}) {
        acf {
          faqs {
            question
            answer
            isDefaultOpen
          }
        }
      }
    }  
  `);
  const { faqs } = data.wordpressPage.acf;
  return (
    <Section
      padding="0px"
    >
      <StyledPricingFaqSection>
        <h4>Frequently Asked Questions</h4>
        {
          faqs.map((faq, index) => {
            return (
              <Accordian
                className="faq"
                key={index}
                title={faq.question}
                isDefaultOpen={faq.isDefaultOpen === 'open' ? true : false}
              >
                {faq.answer}
              </Accordian>
            )
          })
        }
      </StyledPricingFaqSection>
    </Section>
  )
}

PricingFaqSection.defaultProps = {

}

export default PricingFaqSection