import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import TickGreen from '../../../../../assets/pricing/icons/TickGreen.inline.svg'
import CrossRed from '../../../../../assets/pricing/icons/CrossRed.inline.svg'

const StyledPricingTable = styled.div`
  width: 100%;
  padding: 40px;
  display: flex;
  justify-content: center;
  table {
    max-width: 768px;
    margin-bottom: 0px;
    border-collapse: collapse;
    border: 1px solid ${props => props.theme.name === 'dark' ? '#ccc' : '#444'};
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }
  th, td {
    width: 180px;
    color: ${props => props.theme.name === 'dark' ? '#ccc' : '#121212'};
  }
  th {
    padding: 20px 20px;
    border-bottom: 1px dashed ${props => props.theme.name === 'dark' ? '#fff' : '#444'};
    font-weight: bold;
    font-size: 16px;
  }
  td {
    padding: 20px 20px;
    border-bottom: 1px dashed ${props => props.theme.name === 'dark' ? '#fff' : '#444'};
    font-size: 14px;
  }
  tr th:nth-child(1), td:nth-child(1) {
    width: 280px;
  }
  /* First Column */
  tr td:nth-child(1) {
    color: ${props => props.theme.name === 'dark' ? '#b9b9b9' : '#333'};
  }
  /* Last Column */
  tr th:nth-child(3), td:nth-child(3) {
    color: ${props => props.theme.name === 'dark' ? '#fff' : '#555'};
    background: ${props => props.theme.name === 'dark' ? '#555' : '#ddd'};
  }
`

const PricingTable = (props) => {
  const data = useStaticQuery(graphql`
    query tableData {
      wordpressPage(title: {eq: "Pricing"}) {
        acf {
          pricing_detailed_table {
            header {
              c
            }
            body {
              c
            }
          }
        }
      }
    }
  `)
  const { header, body } = data.wordpressPage.acf.pricing_detailed_table
  return (
    <StyledPricingTable>
      <table>
        <thead>
          <tr>
            {
              header.map((header_item, index) => {
                return (
                  <th key={index}>{header_item.c}</th>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            body.map((row, rowIndex) => {
              return (
                <tr key={rowIndex}>
                  {
                    row.map((cell, cellIndex) => {
                      return (
                        <td key={cellIndex}>
                          {
                            /* Ensure Tick and Cross appear when entered in table */
                            (function () {
                              switch (cell.c) {
                                case 'N':
                                  return <CrossRed />;
                                case 'Y':
                                  return <TickGreen />;
                                default:
                                  return cell.c;
                              }
                            })()
                          }
                        </td>
                      )
                    })
                  }
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </StyledPricingTable>
  )
}

PricingTable.defaultProps = {

}

export default PricingTable