import React from 'react'
import styled from 'styled-components'
import Hero from '../../../resuable/hero/hero'
import SEO from '../../../resuable/seo/seo'
import { useStaticQuery, graphql } from 'gatsby'

const StyledPricingHeroSection = styled.header`
  width: 100%;
  .no-cc {
    padding: 4px 8px;
    border-radius: 30px;
    color: #fff;
    background: #00695c;
    font-size: 14px;
  }
`

const PricingHeroSection = (props) => {
  const data = useStaticQuery(graphql`
    query pricingSEO {
      wordpressPage(slug: {eq: "pricing"}) {
        acf {
          hero_section {
            page_subtitle
            page_title
          }
        }
        yoast_meta {
          yoast_wpseo_title
          yoast_wpseo_twitter_description
          yoast_wpseo_metadesc
        }
      }
    }  
  `)
  const { acf, yoast_meta } = data.wordpressPage;
  return (
    <StyledPricingHeroSection>
      <SEO 
        title={yoast_meta.yoast_wpseo_title}
      />
      <Hero 
        title={acf.hero_section.page_title}
      >
        <h2>{acf.hero_section.page_subtitle}</h2>
        <h3 className="no-cc">No credit card required!</h3>
      </Hero>
    </StyledPricingHeroSection>
  )
}

PricingHeroSection.defaultProps = {

}

export default PricingHeroSection