import React, { useState } from 'react'
import styled from 'styled-components'

import AccordianPlus from '../../../assets/icons/AccordianPlus.inline.svg'
import AccordianMinus from '../../../assets/icons/AccordianMinus.inline.svg'

const StyledAccordian = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #ededed;
  margin: 0px;
  div.container__title {
    cursor: pointer;
    width: 100%;
    padding: 6px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #dedede;
    border-bottom: 0.5px solid #ddd;
    font-size: 20px;
    h5 {
      display: inline-block;
      width: 100%;
      margin-bottom: 0px;
      font-size: 18px;
      color: #222;
    }
    svg {
      height: 24px;
      width: 24px;
    }
  }
  div.container__body {
    box-sizing: border-box;
    width: 100%;
    height: ${props => props.isOpen ? 'auto' : '0px'};
    overflow: hidden;
    padding: ${props => props.isOpen ? '8px 6px' : '0px'};
    background: #fff;
    font-size: 16px;
    transition: 1s all;
  }
`

const Accordian = ({ title, isDefaultOpen, children, className }) => {
  // Local State
  const [isOpen, toggleOpen] = useState(isDefaultOpen);
  return (
    <StyledAccordian
      isOpen={isOpen}
      className={className}
    >
      <div className="container__title" onClick={() => toggleOpen(!isOpen)}>
        <h5>{title}</h5>
        { isOpen ? <AccordianMinus /> : <AccordianPlus /> }
      </div>
      <div className="container__body">
        {children}
      </div>
    </StyledAccordian>
  )
}

Accordian.defaultProps = {
  title: 'Lorem Ipsum',
  isDefaultOpen: false
}

export default Accordian