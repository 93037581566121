import React from 'react'
import Modal from 'react-modal';

import './modal.scss'
// import styled from 'styled-components'

// Binding modal to root (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#___gatsby')

const ModalAdapter = ({ className, heading, ...props }) => {
  return (
    <React.Fragment>
      <Modal
        className={`${className}--ReactModal__Content`}
        overlayClassName={`${className}--ReactModal__Overlay`}
        {...props}
      >
        {heading !== undefined && (
          <div className="heading">
            <h4>{heading}</h4>
          </div>
        )}
        {props.children}
        <button
          onClick={props.onRequestClose}
          className="cross-mark"
        >X</button>
      </Modal>
    </React.Fragment>
  )
}

export default ModalAdapter