import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../../../../../utils/styles/styles';
import { ButtonType } from '../../../../../resuable/button';


const StyledTalkForm = styled.div`
  form {
    width: 100%;
    div.field {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      label {
        color: ${colors.gray};
      }
      input {
        width: 100%;
        height: 36px;
        padding: 0px 12px;
        background: transparent;
        color: #fff;
        border: none;
        border-bottom: 2px solid ${colors.gray};
        font-size: 16px;
        &::placeholder {
          color: ${colors.gray};
        }
        &:focus {
          border-bottom: 2px solid ${colors.pri};
          outline: none;
        }
      }
      button {
        
      }
    }
  }
`

const TalkForm = (props) => {
  const [input, setInput] = useState({
    name: '',
    organization: '',
    contact_num: '',
    email_id: '',
    message: ''
  });

  const handleChange = (e) => {
    let newInput = input
    newInput[e.target.name] = e.target.value
    setInput({
      ...newInput
    })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(input)

    const config = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(input)
    }
    try {
      const res = await fetch('https://api.leadmirror.com/api/v1/homepage/contact-us/', config);
      // const data = await res.json();
      const status = res.status;
      console.log(status)
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <StyledTalkForm>
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            value={input.name}
            onChange={handleChange}
          />
        </div>

        <div className="field">
          <label htmlFor="email_id">Email</label>
          <input
            type="text"
            name="email_id"
            placeholder="name@company.com"
            value={input.email_id}
            onChange={handleChange}
          />
        </div>

        <div className="field">
          <label htmlFor="contact_num">Contact Number</label>
          <input
            type="text"
            name="contact_num"
            placeholder="Contact Number"
            value={input.contact_num}
            onChange={handleChange}
          />
        </div>
        
        <div className="field">
          <label htmlFor="Website">Organization</label>
          <input
            type="text"
            name="organization"
            placeholder="Your Company"
            value={input.organization}
            onChange={handleChange}
          />
        </div>
        <div className="container__form--button">
          <ButtonType.Base>
            Submit
          </ButtonType.Base>
        </div>
      </form>
    </StyledTalkForm>
  )
}

TalkForm.defaultProps = {

}

export default TalkForm