/**
 * ! props: planName, basePrice, features, discountOffer
 */

import React, { useState } from 'react'
import styled from 'styled-components';

import Tick from '../../../../../assets/pricing/icons/Tick.inline.svg'
import Cross from '../../../../../assets/pricing/icons/Cross.inline.svg'

import { colors } from '../../../../../utils/styles/styles';
import { pricingDiscountCalculator } from '../../../../../utils/helpers/pricing-calc';
import ModalAdapter from '../../../../resuable/modal/ModalAdapter';

import TalkForm from './talk-form/talk-form';
import './talk-form/talk-form.scss';
import { getSourceLink } from '../../../../../utils/tracker/links';

const StyledPricingBlock = styled.div`
  box-sizing: border-box;
  width: 260px;
  height: auto;
  margin: 0px 10px;
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  color: #000;
  border: 1px solid transparent;
  box-shadow: -5px 14px 23px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  div.container__title {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .planName {
      margin: 0px;
      font-size: 24px;
      color: inherit;
    }
    .planDescription {
      margin-bottom: 4px;
      font-size: 12px;
      text-align: center;
    }
    /* ONLY SHOWS UP IF isDiscountRunning = true */
    span.discount_show {
      position: absolute;
      bottom: -20px;
      padding: 0px 14px;
      background: goldenrod;
      color: #fff;
      font-size: 10px;
    }
  }
  div.container__price {
    margin: 10px 0px 0px;
    background: ${colors.priDark};
    color: #fff;
    /* height: 140px;
    width: 140px; */
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: -5px 14px 23px rgba(0, 0, 0, 0.25);
    animation: 4s blob-move ease-in-out infinite both;
    div.container__price--number {
      margin-bottom: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        display: inline-block;
        color: #fff;
        line-height: 100%;
        font-weight: bold;
      }
      span.price__normal {
        color: ${props => props.discountOffer.discount_running  ? '#dcdcdc' : '#fff'};
        font-size: ${props => props.discountOffer.discount_running  ? '14px' : '36px'};
        text-decoration: ${props => props.discountOffer.discount_running  ? 'line-through' : 'none'};
      }
      span.price__discounted {
        margin-left: 6px;
        font-size: 36px;
      }
    }
    span.container__price--month {
      color: #fff;
      line-height: 100%;
    }
  }
  div.container__features {
    margin: 20px 0 20px;
    div.container__features--feature {
      padding: 4px 4px;
      svg path {
        fill: #8B8B8B;
      }
      span.text {
        color: ${colors.gray};
        margin-left: 4px;
        font-size: 11px;
      }
    }
  }
  div.container__button {
    width: 100%;
    display: flex;
    justify-content: center;
    button {
      cursor: pointer;
      width: 90%;
      padding: 8px 20px;
      color: #fff;
      background: ${colors.priGradient};
      border: none;
      border-radius: 4px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.35);
      font-size: 12px;
      font-weight: 700;
      transition: 1s all;
      &:hover {
        transform: scale(1.03);
        transition: 1s all;
      }
    }
  }
  div.disclaimer {
    padding: 8px 0px;
    span {
      font-size: 10px;
    }
  }
  @media (max-width: 768px) {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
  @keyframes blob-move {
    0% {
      border-radius: 80% 80% 60% 70%;
    }
    50% {
      border-radius: 70% 60% 80% 80%;
    }
    100% {
      border-radius: 80% 80% 60% 70%;
    }
  }
`

const StyledPricingBlockFeatured = styled(StyledPricingBlock)`
  position: relative;
  top: -20px;
  background: ${colors.secGradient};
  div.container__badge {
    position: absolute;
    top: -18px;
    height: 36px;
    padding: 0px 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.terGradient};
    border-radius: 20px;
    box-shadow: -5px 14px 23px rgba(0, 0, 0, 0.25);
    span {
      color: #fff;
      font-size: 14px;
    }
  }
  div.container__title {
    .planName {
      color: #fff;
    }
    .planDescription {
      color: #fff;
    }
  }
  div.container__price {
    background: #fff;
    color: ${colors.terDark};
    animation: none;
    div.container__price--number {
      span {
        color: ${colors.terDark};
      }
      span.price__normal {
        color: ${props => props.discountOffer.discount_running  ? '#dadada' : colors.terDark};
      }
      span.price__discounted {
        color: ${colors.terDark};
      }
    }
    span.container__price--month {
      color: ${colors.terDark};
    }
  }
  div.container__features {
    div.container__features--feature {
      svg path {
        fill: #fff;
      }
      span.text {
        color: ${colors.white};
      }
    }
  }
  div.container__button {
    button {
      background: #fff;
      color: #000;
    }
  }
  div.disclaimer {
    span {
      color: #dedede;
    }
  }
  @media (max-width: 768px) {
    position: relative;
    top: 0px;
  }
`

const PricingBlockInner = ({ planName, planDescription, basePrice, features, planButton, discountOffer }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false)
  }
  const afterOpenModal = () => {
    console.log('Closed');
  }
  const handleClick = (action) => {
    if(action === 'talk') {
      openModal();
    } else if(action === 'trial') {
      window.open(`https://app.leadmirror.com/#/signup/wizard/1?initLandingPage=${getSourceLink()}`);
    }
  }

  const discountedPrice = pricingDiscountCalculator(basePrice, parseInt(discountOffer.discount_percentage));
  return (
    <React.Fragment>
      <div className="container__title">
        <h3 className="planName">{planName}</h3>
        <p className="planDescription">{planDescription}</p>
        {discountOffer.discount_running && <span className="discount_show">Diwali Sale: {discountOffer.discount_percentage}% off</span>}
      </div>
      <div className="container__price">
        <div className="container__price--number">
          <span className="price__normal">${basePrice}</span>
          {discountOffer.discount_running && <span className="price__discounted">${discountedPrice}</span>}
        </div>
        <span className="container__price--month">/mo</span>
      </div>
      <div className="container__features">
        {
          features.map((feature, index) => {
            return (
              <div className="container__features--feature" key={index}>
                {feature.flag ? <Tick /> : <Cross />}
                <span className="text">{feature.feature}</span>
              </div>
            )
          })
        }
      </div>
      <div className="container__button">
        <button
          onClick={() => handleClick(planButton.action)}
          id={planButton.tag_manager_id}
        >
          {planButton.label}
        </button>
      </div>
      <div className="disclaimer">
        <span>No credit card needed!</span>
      </div>
      <ModalAdapter
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        className="talkForm"
        heading="Lets get in touch!"
      >
        <TalkForm />
      </ModalAdapter>
    </React.Fragment>
  )
}

const PricingBlock = (props) => {
  if (props.isMostPopular) {
    return (
      <StyledPricingBlockFeatured
        {...props}
      >
        <div className="container__badge">
          <span>
            Best Valued Pack
          </span>
        </div>
        <PricingBlockInner
          {...props}
        />
      </StyledPricingBlockFeatured>
    )
  }
  else {
    return (
      <StyledPricingBlock
        {...props}
      >
        <PricingBlockInner
          {...props}
        />
      </StyledPricingBlock>
    )
  }
}

PricingBlockInner.defaultProps = {
  planName: 'Starter Plan',
  planDescription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque, veniam excepturi! Fuga explicabo autem maxime?',
  basePrice: 99,
  features: [
    { flag: true, text: 'User(s) : 1' },
    { flag: true, text: 'Organic Search Position: 50 Keywords' },
    { flag: true, text: 'SEO Audit: 25 URLs' },
    { flag: false, text: 'Link Sense: 10 URLs' },
    { flag: true, text: 'A2i Hours : 1' },
  ],
  planButton: {
    action: null,
    label: 'Proceed'
  },
  discountOffer: {
    discount_running: true,
    discount_percentage: 20,
    discount_end_date: '',
  },
}

PricingBlock.defaultProps = {
  isMostPopular: true,
}

export default PricingBlock
