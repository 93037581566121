import React from 'react'
import styled from 'styled-components'
import PricingTable from './pricing-table/pricing-table'
import Section from '../../../resuable/section/section'

const StyledPricingTableSection = styled.div`
  width: 100%;
  padding: 60px 20px 40px;
  div.container__heading {
    margin-bottom: 0px;
    h3 {
      text-align: center;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`

const PricingTableSection = (props) => {
  return (
    <Section
      padding="0px"
    >
      <StyledPricingTableSection>
        <div className="container__heading">
          <h3>Detailed Breakdown of all plans</h3>
        </div>
        <PricingTable />
      </StyledPricingTableSection>
    </Section>
  )
}

PricingTableSection.defaultProps = {

}

export default PricingTableSection