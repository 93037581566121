import React, { useState } from 'react'
import styled from 'styled-components'
import Section from '../../../resuable/section/section'
import PricingBlock from '../pricing-plans-section/pricing-block/pricing-block'
import { pricingPeriodCalculator } from '../../../../utils/helpers/pricing-calc'
import { colors } from '../../../../utils/styles/styles'
import { useStaticQuery, graphql } from 'gatsby'

const StyledPricingPlansSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  div.container__priceperiodcontrol {
    width: 100%;
    margin-bottom: 80px;
    div.container__buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      button {
        height: 36px;
        padding: 0 36px;
        border: none;
        color: #797979;
        background: #F2F2F2;
        font-size: 14px;
        &:first-child {
          border-radius: 18px 0px 0px 18px;
        }
        &:last-child {
          border-radius: 0px 18px 18px 0px;
        }
        &:hover {
          background: ${colors.priGradient};
          color: #fff;
          transition: 1s all;
        }
        &:focus {
          outline: none;
        }
      }
      button.active {
        background: ${colors.priGradient};
        color: #fff;
      }
    }
  }
  div.container__priceperiodblock {
    padding: 20px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (max-width: 480px) {
    div.container__priceperiodcontrol {
      margin-bottom: 40px;
      padding: 0 20px;
      div.container__buttons {
        button {
          height: 54px;
          font-size: 14px;
        }
      }
    }
    div.container__priceperiodblock {
      flex-direction: column;
      align-items: center;
    }
  }
`

const PricingPlansSection = (props) => {
  /* State Hook */
  const [planPeriod, setPlanPeriod] = useState('yearly');

  const data = useStaticQuery(graphql`
    query allPlansQuery {
      wordpressPage(title: {eq: "Pricing"}) {
        acf {
          pricing_plans {
            plan_order
            plan_name
            plan_description
            base_price
            plan_features {
              flag
              feature
            }
            plan_button {
              action
              label
              tag_manager_id
            }
            plan_featured
            plan_discount_offer {
              discount_running
              discount_percentage
              discount_end_date
            }
          }
        }
      }
    }
  `)

  const { pricing_plans } = data.wordpressPage.acf;

  /* Sets price plan in state based on button name */
  const handlePlanPeriodChange = (e) => {
    console.log(e.target.name)
    if (e.target.name === 'monthly') {
      setPlanPeriod('monthly')
    }
    else if (e.target.name === 'halfyearly') {
      setPlanPeriod('halfyearly')
    }
    else if (e.target.name === 'yearly') {
      setPlanPeriod('yearly')
    }
  }

  return (
    <Section
      fullwidth={true}
      padding="0px"
    >
      <StyledPricingPlansSection
        planPeriod={planPeriod}
      >
        <div className="container__priceperiodcontrol">
          <div className="container__buttons">
            <button
              name="monthly"
              onClick={handlePlanPeriodChange}
              className={planPeriod === 'monthly' ? 'active' : 'inactive'}
            >
              Monthly
            </button>
            <button
              name="halfyearly"
              onClick={handlePlanPeriodChange}
              className={planPeriod === 'halfyearly' ? 'active' : 'inactive'}
            >
              Half-Yearly
            </button>
            <button
              name="yearly"
              onClick={handlePlanPeriodChange}
              className={planPeriod === 'yearly' ? 'active' : 'inactive'}
            >
              Annually
            </button>
          </div>
        </div>
        <div className="container__priceperiodblock" id="pricing_blocks">
          {
            pricing_plans.map((plan, index) => {
              return (
                <PricingBlock 
                  key={index}
                  isMostPopular={plan.plan_featured}
                  discountOffer={plan.plan_discount_offer}
                  planName={plan.plan_name}
                  planDescription={plan.plan_description}
                  basePrice={pricingPeriodCalculator(plan.base_price, planPeriod)}
                  planButton={plan.plan_button}
                  features={plan.plan_features}
                />
              )
            })
          }
        </div>
      </StyledPricingPlansSection>
    </Section>
  )
}

PricingPlansSection.defaultProps = {

}

export default PricingPlansSection