/**
 * Calculates Final price after discount to be given based on time period selection
 * @param { Base Monthly Price of a Plan, eg: Starter: 19.99} basePrice 
 * @param { Time Period for which discount is to be given on the base price, eg: 'monthly'} period 
 */
export const pricingPeriodCalculator = (basePrice, period) => {
  // Calculating Discount
  let discount;
  if(period === 'monthly') {
    discount = 0
  }
  else if(period === 'halfyearly') {
    discount = 10
  }
  else if(period === 'yearly') {
    discount = 25
  }
  else {
    discount = 100;
  }

  // Calculating Modified Price
  const modifiedPrice = Math.round((basePrice - (basePrice * discount/100)) * 100) / 100
  
  // Returning Modified Price
  return modifiedPrice
}

export const pricingDiscountCalculator = (basePrice, discountPercent) => {
  const modifiedPrice = Math.round((basePrice - (basePrice * discountPercent/100)) * 100) / 100;
  return modifiedPrice;
}

// export const pricingCurrencyCalculator = (price, targetCurrency) => {
//   return modifiedPrice
// }