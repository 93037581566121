import React from "react"

import Layout from "../components/resuable/layout/layout";
import PricingPlansSection from "../components/page/pricing-components/pricing-plans-section/pricing-plans-section"
import PricingTableSection from "../components/page/pricing-components/pricing-table-section/pricing-table-section"
import Joinus from "../components/resuable/joinus/joinus"
import PricingHeroSection from "../components/page/pricing-components/pricing-hero-section/pricing-hero-section"
import PricingFaqSection from "../components/page/pricing-components/pricing-faq-section/pricing-faq-section"
import useWindowSize from "../components/hooks/useWindowSize";

const Pricing = () => {
  const size = useWindowSize();
  return (
    <Layout>
      <PricingHeroSection />
      <PricingPlansSection />
      <PricingTableSection />   
      <PricingFaqSection />
      <Joinus />
    </Layout>
  )
}

export default Pricing
